<template>
    <div>
        <div class="Box">
            <div style="position: relative;">
                <el-progress type="circle" :width="60" :percentage="percentage" :show-text="false" stroke-width="4"></el-progress>
                <div class="time">
                    <h2 v-if="countdown > 0">{{ countdown }}秒</h2>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            countdown: 30,//倒计时
            countdownNum: 30,
            timer: null,//定时器
            is_pause: true,//是否暂停
            percentage: 100,//圆环百分比
        }
    },
    methods: {
        updateTokenIfNeeded() {
            const currentSecond = new Date().getSeconds();
            this.countdown = 30 - (currentSecond % 30);
            this.percentage = (this.countdown / this.countdownNum).toFixed(2) * 100; 
        },
    },
    created() {
        setInterval(this.updateTokenIfNeeded, 1000);
    }
}

</script>

<style scoped>
.Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 10px 5px;
    box-sizing: border-box;
}

.Box .time {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    font-size: 1px;
}
</style>
