<template>
  <div id="app">
    <el-container>
      <el-header>
        <h1 class="text-center display-4">TOTP Token Manager</h1>
      </el-header>
      <div class="el-main">
        <el-row justify="center" class="input-row">
          <el-input v-model="remarkInput" placeholder="用户信息" size="medium"></el-input>
          <el-input v-model="secretInput" placeholder="密钥" size="medium"></el-input>
          <el-button @click="saveSecret" type="primary" size="medium">添加</el-button>
        </el-row>
        <el-row justify="center" class="mt-4">
          <el-col>
            <el-upload action="#" class="upload-demo" :accept="'image/*'" :show-file-list="false" drag
              :before-upload="handleFileUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将令牌二维码拖拽到此区域，或<em>点击上传</em></div>
            </el-upload>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-button-group>
              <el-upload action="#" accept="application/json" :show-file-list="false" :before-upload="handleImport">
                <el-button type="success" size="mini">导入</el-button>
              </el-upload>
              <el-button @click="handleExport" type="primary" size="mini">导出</el-button>
              <el-button @click="handleClear" type="danger" size="mini">清空</el-button>
            </el-button-group>
          </el-col>
          <el-col>
            <el-tag type="sucess" v-if="showTag">当前登录的Github账户 {{ userName }}</el-tag>
          </el-col>
        </el-row>
        <el-row justify="center" class="mt-4">
          <el-col :span="11">
            <timeCount></timeCount>
          </el-col>

          <el-col :span="13">

            <el-button-group>

              <el-tag type="info">是否同步</el-tag>

              <el-tooltip placement="top">
                <div slot="content">开启同步功能说明<br /><br />1、打开同步按钮,通过你的Github账号对Gist存储进行授权
                  <br /><br />2、Gist上创建名为【totp_secret_backup.json】的文件
                  <br /><br />3、点【上传】会把本地数据覆盖到Gist
                  <br /><br />4、点【恢复】会把Gist数据和本地数据合并
                  <br /><br />同步数据过程中本站不存储任何数据
                </div>
                <el-switch v-model="syncluodflag" active-color="#13ce66" inactive-color="#ff4949"
                  @change="handleSwitchChange">
                </el-switch>
              </el-tooltip>

              <el-button type="primary" size="mini" @click="uploadSync" :disabled="!syncluodflag">上传</el-button>
              <el-button type="success" size="mini" @click="downloadSync" :disabled="!syncluodflag">恢复</el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row justify="center" class="mt-4">
          <el-col>
            <el-table :data="tokenData">
              <el-table-column label="序号" width="60">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="用户信息" width="150"></el-table-column>
              <el-table-column prop="token" label="令牌" width="100"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button-group>


                    <el-link @click="copyToken(scope.row.token)" type="primary">复制 |</el-link>
                    <el-link @click="showQRCode(scope.row.secret, scope.row.remark)" type="success">显示 |</el-link>
                    <el-link @click="deleteToken(scope.row.uuid)" type="danger">删除</el-link>

                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-dialog :visible.sync="showQRCodeDialog" title="可直接用APP扫描" width="20%" align="center">
          <div class="qrcode" id="qrcode"></div>
        </el-dialog>
      </div>
      <el-footer>
        <div class="container text-center">

        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

import { TOTP } from 'jsotp';
import QRCode from 'qrcodejs2';
import jsQR from 'jsqr';
import axios from 'axios';

import timeCount from './components/timeCount.vue';

export default {
  components: {
    timeCount
  },
  name: 'App',
  data() {
    return {
      remarkInput: '',
      secretInput: '',
      tokenData: [],
      secondsToRefresh: 0,
      showQRCodeDialog: false,
      closeQRCodeDialog: false,
      qrCodeSecret: '',
      syncluodflag: localStorage.getItem('remoteSync') === '1',
      remoteFileName: 'totp_secret_backup.json',
      showTag: false,
      userName: '',
    };
  },
  methods: {
    generateTOTP(secret) {
      // Generate TOTP code using the secret
      const otp = new TOTP(secret);
      const code = otp.now();
      return code;

    },
    updateTokenIfNeeded() {
      const currentSecond = new Date().getSeconds();
      if (currentSecond % 30 === 0) {
        this.tokenData.forEach((token) => {
          token.token = this.generateTOTP(token.secret);
        });
      } else {
        this.secondsToRefresh = 30 - (currentSecond % 30);
      }
    },
    deleteAll() {
      if (confirm("您确定要执行这个操作吗？")) {
        this.tokenData = [];
        this.saveTokens();
      }
    },
    saveSecret() {
      if (!this.remarkInput || !this.secretInput) {
        this.$message.error('请输入网站备注和密钥。');
        return;
      }
      try {
        this.generateTOTP(this.secretInput);
      } catch (e) {
        this.$message.error("可能输入的密钥不合法。本站目前仅支持base32形式的密钥。" + e);
        return;
      }
      const token = {
        uuid: this.generateUUID(),
        remark: this.remarkInput,
        secret: this.secretInput,
        token: this.generateTOTP(this.secretInput)
      };
      this.tokenData.push(token);
      this.saveTokens();
      this.remarkInput = '';
      this.secretInput = '';
    },
    copyToken(token) {
      navigator.clipboard.writeText(token)
        .then(() => this.$message.success('复制成功！'))
        .catch(() => this.$message.error('复制失败'));
    },
    showQRCode(secret, remark) {
      this.showQRCodeDialog = true;
      const otpAuthUri = `otpauth://totp/${remark}?secret=${secret}&issuer=${remark}`;
      this.$nextTick(() => {
        document.getElementById("qrcode").innerHTML = "";
        new QRCode('qrcode', { text: otpAuthUri, width: 120, height: 120, });
      })


    },
    deleteToken(uuid) {
      this.tokenData = this.tokenData.filter(token => token.uuid !== uuid);
      this.saveTokens();
    },
    handleFileUpload(file) {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        this.$message.error('只允许上传图片文件！');
        return false; // 取消上传
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            const result = this.parseOtpAuthLink(code.data);
            if (result) {
              console.log(result.remark + result.secret)
              if (result.remark && result.secret) {
                this.tokenData.push({
                  uuid: this.generateUUID(),
                  remark: result.remark,
                  secret: result.secret,
                  token: this.generateTOTP(result.secret)
                });
                this.saveTokens();
              }
            } else {
              this.$message.error("解析二维码失败，请选择或者粘贴正确的totp令牌二维码");
            }
            //qrCodeResult.innerHTML = `<p>识别结果: </p>`;
          } else {
            this.$message.error('未识别到二维码');
          }
        };
      };
      reader.readAsDataURL(file);
    },
    parseOtpAuthLink(link) {
      console.log('ccccccccc');
      console.log(link);
      // 使用正则表达式提取remark和secret
      const regex = /otpauth:\/\/totp\/([^?]+)\?secret=([^&]+)/;
      const match = link.match(regex);

      console.log(match);
      if (match) {
        const remark = match[1].trim();
        const secret = match[2].trim();
        return { remark, secret };
      } else {
        return null; // 如果链接格式不正确，则返回null
      }
    },
    handleImport(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const remarkSecretPairs = JSON.parse(e.target.result);
        remarkSecretPairs.forEach(pair => {
          if (pair.remark && pair.secret) {
            this.tokenData.push({
              uuid: pair.uuid,
              remark: pair.remark,
              secret: pair.secret,
              token: this.generateTOTP(pair.secret)
            });
          }
        });
        this.saveTokens();
      };
      reader.readAsText(file);
      return false;
    },
    handleExport() {
      const jsonContent = JSON.stringify(this.tokenData.map(token => ({
        uuid: token.uuid,
        remark: token.remark,
        secret: token.secret
      })));
      const blob = new Blob([jsonContent], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'backup.json';
      a.click();
    },
    handleClear() {

      this.$confirm('您确定要执行这个操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tokenData = [];
        this.saveTokens();
        this.$message({
          type: 'success',
          message: '清空成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消清空'
        });
      });

    },
    saveTokens() {
      localStorage.setItem('tokenData', JSON.stringify(this.tokenData));
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    handleSwitchChange(value) {
      if (value) {
        if (localStorage.getItem("ghUsername") != null) {
          this.showTag = true;
          this.userName = localStorage.getItem("ghUsername");
        }

        localStorage.setItem('remoteSync', '1');
        if (!localStorage.getItem('ghToken')) {
          this.loginWithGitHub();
          return;
        }
      } else {
        this.showTag = false;
        localStorage.setItem('remoteSync', '0');
      }

    },
    uploadSync() {
      const savedKeys = JSON.parse(localStorage.getItem('tokenData'));
      if (savedKeys.length === 0) {
        this.$message.error('没有需要上传的数据！');
        return;
      }

      if (!localStorage.getItem('ghToken')) {
        this.loginWithGitHub();
        return;
      }

      if (localStorage.getItem('ghGistId') == null) {
        this.$message.error(`请先在github上创建文件名为 ${this.remoteFileName} 的gist，然后刷新本页面`);
        return;
      }

      const uploadKeys = [];
      savedKeys.forEach((key) => {
        if (key.uuid != undefined && key.secret != undefined && key.remark != undefined) {
          uploadKeys.push(key);
        }
      });


      this.$confirm('上传将会覆盖服务端的内容，如果想要增量合并，请先点击下载后确认本地数据没问题再上传！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateGistContent(JSON.stringify(uploadKeys)).then(() => {
          this.$message.success("上传成功");
        }).catch(error => {
          console.error('Error:', error);
          this.$message.error("上传失败");
        });
      })



    },
    loginWithGitHub() {

      this.$confirm('你确定要使用同步功能吗?将会登陆Github以同步数据到Gist，过程中依赖客户端能够访问Github。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const redirectUri = location.origin;
        const githubAuthUrl = `https://github.com/login/oauth/authorize?client_id=Iv1.fe1bef9a2c8c256d&redirect_uri=${redirectUri}`;
        window.location.href = githubAuthUrl;
      }).catch(() => {
        localStorage.setItem('remoteSync', '0');
        this.syncluodflag = false;
        this.$message({
          type: 'info',
          message: '已取消同步'
        });
      });
    },
    checkGitHubAuthentication() {
      if (new URLSearchParams(window.location.search).get('code')) {
        const requestBody = {
          code: new URLSearchParams(window.location.search).get('code'),
        };

        console.log(new URLSearchParams(window.location.search).get('code'));
        axios
          .post('https://gittoken.ping8.top', requestBody, {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          })
          .then((response) => {
            const responseJson = response.data;
            console.log(responseJson);
            console.log(response.data.access_token);
            if (responseJson.error != undefined) {
              this.$message.error('登录失败，请重试');
            } else {
              const accessToken = responseJson.access_token;
              localStorage.setItem('ghToken', accessToken);
              this.$message.success('登录成功');
              //this.getUserUsername(() => {location.href = '/';});	
              location.href = '/';
            }

          })
          .catch((error) => {
            this.$message.error('登录失败，请重试');
            console.error('Error:', error);
          });
      }
    },
    getUserUsername() {
      console.log('get username info');
      return new Promise((resolve, reject) => {
        fetch('https://api.github.com/user', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ghToken")}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            // Extract the username from the response data
            console.log(data);
            if (data.login == undefined) {
              reject(new Error('Failed to get username.'));
              this.loginWithGitHub();
            } else {
              localStorage.setItem('ghUsername', data.login);

            }
            resolve(data.login);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    findGistId() {
      fetch(`https://api.github.com/users/${localStorage.getItem("ghUsername")}/gists`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ghToken")}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          let foundGistId = null;
          for (const gist of data) {
            const gistId = gist.id;

            if (gist.files && gist.files[this.remoteFileName]) {
              foundGistId = gistId;
              break;
            }
          }
          if (foundGistId) {
            console.log(`Found Gist ID: ${foundGistId}`);
            localStorage.setItem('ghGistId', foundGistId);
          } else {
            //console.log(`Gist with filename '${remoteFileName}' not found.`);
            this.$message.error(`请先在github上创建文件名为${this.remoteFileName}的gist，然后刷新本页面`);
            console.log("please create gist manually with name" + this.remoteFileName);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },

    updateGistContent(newContent) {
      return new Promise((resolve, reject) => {
        // Construct the Gist URL using the Gist ID
        const gistUrl = `https://api.github.com/gists/${localStorage.getItem("ghGistId")}`;
        // Prepare the payload for updating the Gist
        const gistUpdatePayload = {
          files: {
            [this.remoteFileName]: {
              content: newContent,
            },
          },
        };
        fetch(gistUrl, {
          method: 'PATCH', // Use PATCH method for updating
          headers: { Authorization: `Bearer ${localStorage.getItem("ghToken")}` },
          body: JSON.stringify(gistUpdatePayload),
        })
          .then(response => response.json())
          .then(updatedGist => {
            // Check if the Gist was successfully updated
            if (updatedGist && updatedGist.id) {
              resolve(updatedGist);
            } else {
              reject(new Error('Failed to update the Gist.'));
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getGistContent() {
      return new Promise((resolve, reject) => {
        const gistUrl = `https://api.github.com/gists/${localStorage.getItem("ghGistId")}`;
        fetch(gistUrl, {
          method: 'GET',
          headers: { Authorization: `Bearer ${localStorage.getItem("ghToken")}` },
        })
          .then(response => {
            if (response.status === 200) { // 检查状态代码是否为 200 OK
              return response.json();
            } else if (response.status === 401) {
              this.$message.error(`疑似凭证失效，请重新登录Github`);
              this.loginWithGitHub();
            } else if (response.status === 404) {
              this.$message.error(`请先在github上创建文件名为${this.remoteFileName}的gist`);
            } else {
              this.$message.error(`未知错误`);
              reject(new Error(`Failed to fetch data. Status code: ${response.status}`));
            }
          })
          .then(data => {
            const files = data.files;
            if (files && files[this.remoteFileName]) {
              const content = files[this.remoteFileName].content;
              resolve(content);
            } else {
              this.$message.error(`请先在github上创建文件名为${this.remoteFileName}的gist`)
              reject(new Error(`File '${this.remoteFileName}' not found in the Gist.`));
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    downloadSync() {
      if (localStorage.getItem('remoteSync') != '1') {
        this.$message.error("请先登录Github");
        return;
      }
      if (localStorage.getItem('ghGistId') == null) {
        this.$message.error(`请先在github上创建文件名为${this.remoteFileName}的gist，然后刷新本页面`);
        return;
      }
      this.getGistContent().then((content) => {
        var savedKeys = JSON.parse(localStorage.getItem('tokenData')) || [];
        var remoteKeys = JSON.parse(content) || [];
        var addCount = 0;
        remoteKeys.forEach((key) => {
          if (key.uuid != undefined && key.secret != undefined && key.remark != undefined) {
            var result = savedKeys.find(item => {
              return item.uuid == key.uuid;
            });
            if (result == undefined) {
              savedKeys.push(key);
              addCount++;
            } else {
              console.log("key already exists");
            }
          } else {
            savedKeys.push(key);
          }
        });
        localStorage.setItem('tokenData', JSON.stringify(savedKeys));
        this.$message.success(`下载成功，去重后新增了${addCount}个到浏览器`);

        this.tokenData = JSON.parse(localStorage.getItem('tokenData'));
        this.tokenData.forEach((token) => {
          token.token = this.generateTOTP(token.secret);
        });

      }).catch(error => {
        console.error('Error:', error);
        this.$message.error("下载失败");
      });

    },
  },
  computed: {
    sortedTokenData() {
      return this.tokenData.slice().sort((a, b) => a.remark.localeCompare(b.remark));
    }
  },
  created() {
    const storedTokenData = localStorage.getItem('tokenData');
    if (storedTokenData) {
      this.tokenData = JSON.parse(storedTokenData);
      this.tokenData.forEach((token) => {
        token.token = this.generateTOTP(token.secret);
      });
    }

    setInterval(this.updateTokenIfNeeded, 1000);
    console.log(this.syncluodflag);
    if (this.syncluodflag) {
      this.checkGitHubAuthentication();
    }


    if (localStorage.getItem("ghToken") != null) {
      if (localStorage.getItem("ghGistId") != null && localStorage.getItem("ghUsername") != null) {
        console.log(localStorage.getItem("ghGistId"))
        console.log(localStorage.getItem("ghUsername"))

        if (this.syncluodflag) {
          this.showTag = true;
          this.userName = localStorage.getItem("ghUsername");
        }

      } else {
        this.getUserUsername().then(() => {
          this.userName = localStorage.getItem("ghUsername");
          this.showTag = true;
          this.findGistId();
        });

      }

    }
  }
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: 0 auto;
}

.el-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.el-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/deep/ .el-upload {
  width: 100%;
}

/deep/ .el-upload .el-upload-dragger {
  width: 100%;
}
</style>